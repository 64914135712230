export default `
mutation createCustomerV3(
  $kvk_number: String!,
  $company: String!,
  $firstname: String!,
  $lastname: String!,
  $street: [String]!,
  $postcode: String!,
  $city: String!,
  $region: CustomerAddressRegionInput!,
  $country_code: CountryCodeEnum!,
  $telephone: String!,
  $company_type: String!,
  $taxvat: String!,
  $email: String!,
  $gender: Int!,
  $customer_active: Int!,
  $is_subscribed: Boolean!
  $password: String!
  $website: String!
){
  createCustomerV3(
    input: {
      kvk_number: $kvk_number,
      company: $company,
      firstname: $firstname,
      lastname: $lastname,
      street: $street,
      postcode: $postcode,
      region: $region,
      city: $city,
      country_code: $country_code,
      telephone: $telephone,
      company_type: $company_type,
      taxvat: $taxvat,
      email: $email,
      is_subscribed: $is_subscribed,
      customer_active: $customer_active,
      gender: $gender,
      password: $password
      custom_website: $website
    }
  ) {
    customer {
      created_at
      email
    }
  }
}
`