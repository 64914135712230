import useApi from '~/composables/useApi';
import queryString from './queryString.gql';

const useCompanyType = () => {
  const { query } = useApi();
  const getData = async () => {
    return query(queryString, {})
      .then((data) => {
        let parsed = data.data['company_types'].types.map(t => { return {type_value: t }})
        return parsed;
      })
      .catch((e) => {
        return e
      });
  }
  return {
    getData
  }
}

export default useCompanyType