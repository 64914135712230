export default `
mutation customerSupportPage(
  $form_name: String,
  $company_name: String!,
  $company_type: String!,
  $your_name: String!,
  $email_address: String!,
  $message: String,
){
  customerSupportPage(
    input: {
      form_name: $form_name,
      company_name: $company_name,
      company_type: $company_type,
      your_name: $your_name,
      email_address: $email_address,
      message: $message,
    }
  ) {
    message
  }
}
`