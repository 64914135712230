import useApi from '~/composables/useApi';
import queryString from './queryString.gql';

const useRegisterCustomer = () => {
  const { mutate } = useApi();
  const register = async (form) => {
    return mutate(queryString, {
      kvk_number: form.coc,
      company: form.company_name,
      website: form.website,
      firstname: form.first_name,
      lastname: form.last_name,
      street: [form.street, form.street_number],
      postcode: form.zip,
      city: form.city,
      country_code: form.country,
      region: form.state,
      telephone: form.phone,
      company_type: form.company_type,
      taxvat: form.vat,
      email: form.email,
      gender: parseInt(form.gender),
      is_subscribed: form.newsletter,
      customer_active: 0,
      password: form.password,
    })
    .then((data) => {
      return data;
    })
    .catch((e) => {
      return e.response
    });
  }
  return {
    register
  }
}

export default useRegisterCustomer