import useApi from '~/composables/useApi';
import queryString from './queryString.gql';

const useContactUs = () => {
  const { mutate } = useApi();
  const contactUs = async (form) => {
    return mutate(queryString, {
      form_name: form.formname,
      company_name: form.company,
      company_type: form.type,
      your_name: form.name,
      email_address: form.email,
      message: form.message,
    })
    .then((data) => {
      return data;
    })
    .catch((e) => {
      return e.response
    });
  }
  return {
    contactUs
  }
}

export default useContactUs

