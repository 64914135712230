export default `
query {
  ambrandlist(
    imageWidth: 1
    imageHeight: 1
    showCount: true
    displayZero: true
  ) {
    brand_attribute{
      attribute_code
      attribute_options{
        label
        value
      }
    }
   	...Items
  }
}

fragment Items on BrandList {
  items {
    alt
    brandId
    cnt
    description
    image
    img
    label
    letter
    short_description
    url
    meta_description
    meta_keywords
    meta_title
  }
}
`;
